<script>
import appConfig from '@src/app.config'

import PrimaInfoCenterArticleCard from '@components/info-center/prima-info-center-article-card.vue'
import PrimaInfoCenterChapterPanel from '@components/info-center/prima-info-center-chapter-panel.vue'
import PrimaInfoCenterSearch from '@components/info-center/prima-info-center-search.vue'
import PlanitIcon from '@components/vuetify/planit-icon.vue'

import ScrollTo from '@src/directives/scroll-to.vue'

import { matchSingleLawPattern } from '@utils/match-law-pattern'


export default {
  name: 'PrimaInfoCenter',
  components: {
    PrimaInfoCenterSearch,
    PrimaInfoCenterChapterPanel,
    PrimaInfoCenterArticleCard,
    PlanitIcon,
  },
  directives: {
    ScrollTo,
  },
  props: {
    locale: {
      type: String,
      default: 'en',
    },
    searchString: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      loading: true,
      error: null,
      searchTerm: null,
      isSearch: false,
      lawBooks: null,
      chapters: null,
      paragraphs: null,
      scrollToParagraphNo: null,
      selectedLawBook: null,
      selectedArticle: null,
    }
  },
  watch: {
    locale: async function () {
      await this.reset()
    },
    searchString: function (searchString) {
      this.searchTerm = searchString
    },
    searchTerm: async function (searchTerm) {
      await this.setInfoCenterSearchString(searchTerm)

      if (!searchTerm) {
        this.isSearch = false

        return
      }

      const reference = this.matchReferencePattern(searchTerm)
      if (reference) {
        this.isSearch = false
        await this.loadByReference(reference.articleNo, reference.paragraphNo)
      } else {
        this.isSearch = true
        await this.setSelectedLawBook(null, true)
      }
    },
  },
  async created() {
    await this.loadLawBooks()

    this.searchTerm = this.searchString
  },
  methods: {
    async reset() {
      this.error = null
      this.searchTerm = null
      this.isSearch = false
      this.lawBooks = null
      this.chapters = null
      this.paragraphs = null
      this.scrollToParagraphNo = null

      await this.setSelectedLawBook(null)
      await this.setSelectedArticle(null)

      await this.loadLawBooks()
    },
    clearSearchTerm() {
      if (this.isSearch && !this.selectedArticle) {
        this.reset()
        return
      }

      this.searchTerm = null
      this.isSearch = false
      this.scrollToParagraphNo = null
    },
    matchReferencePattern(searchTerm) {
      const result = matchSingleLawPattern(searchTerm)
      return result

    },
    getLawBookByArticle(articleData) {
      const chapterData = articleData.attributes.chapter.data
      const subchapterData = articleData.attributes.subchapter.data

      let lawBookData
      if (chapterData) {
        lawBookData = chapterData.attributes.law_book.data
      } else if (subchapterData) {
        lawBookData = subchapterData.attributes.chapter.data.attributes.law_book.data
      } else {
        throw new Error('article has no chapter')
      }

      return lawBookData
    },
    splitParagraph(paragraph) {
      const sentences = paragraph.split('.\n')
      const lastIndex = sentences.length - 1

      return sentences.map((s, i) => {
        if (i !== lastIndex) {
          return s + '.'
        }

        return s
      })
    },
    async handleSelectLawBook(lawBook, isSearch) {
      if (!isSearch) {
        this.searchTerm = null
      }

      this.scrollToParagraphNo = null

      await this.setSelectedLawBook(lawBook)
    },
    async handleSelectArticle(article) {
      this.searchTerm = null
      this.scrollToParagraphNo = null

      await this.setSelectedArticle(article)
    },
    async handleSelectSearchArticle(article, paragraphNo) {
      const lawBook = this.getLawBookByArticle(article)
      await this.selectByReference(lawBook, article, paragraphNo)
    },
    async handleSelectSearchChapterArticle(lawBook, article) {
      await this.selectByReference(lawBook, article, null)
    },
    async setSelectedLawBook(lawBook) {
      this.selectedLawBook = lawBook

      await this.setSelectedArticle(null)

      if (!lawBook) {
        return
      }

      await this.loadChapters()
    },
    async setSelectedArticle(article) {
      this.selectedArticle = article

      this.paragraphs = null

      if (!article) {
        return
      }

      await this.loadParagraphs()
    },
    async setShowInfoCenter(value) {
      await this.$store.dispatch('global/setShowInfoCenter', value)
    },
    async setInfoCenterSearchString(value) {
      await this.$store.dispatch('global/setInfoCenterSearchString', value)
    },
    async loadLawBooks() {
      if (this.lawBooks) {
        return
      }

      this.loading = true
      this.error = null

      try {
        const url = new URL(`${appConfig.strapiApiBaseUrl}/law-books`)
        url.searchParams.set('locale', this.locale)

        const response = await fetch(url, {
          method: 'GET',
        })
        if (!response.ok) {
          throw new Error(`${response.url} - ${response.status}`)
        }

        this.lawBooks = await response.json()
      } catch (e) {
        this.error = this.$t('info_center.http_error')

        throw e
      } finally {
        this.loading = false
      }
    },
    async loadChapters() {
      if (this.chapters) {
        return
      }

      this.loading = true
      this.error = null

      try {
        const url = new URL(`${appConfig.strapiApiBaseUrl}/chapters`)
        url.searchParams.set('locale', this.locale)
        url.searchParams.append('filters[law_book][id][$eq]', this.selectedLawBook.id)
        url.searchParams.append('sort', 'no:asc')
        url.searchParams.append('populate[articles][sort]', 'no:asc')
        url.searchParams.append('populate[subchapters][populate][articles][sort]', 'no:asc')

        const response = await fetch(url, {
          method: 'GET',
        })
        if (!response.ok) {
          throw new Error(`${response.url} - ${response.status}`)
        }

        this.chapters = await response.json()
      } catch (e) {
        this.error = this.$t('info_center.http_error')

        throw e
      } finally {
        this.loading = false
      }
    },
    async loadParagraphs() {
      if (this.paragraphs) {
        return
      }

      this.loading = true
      this.error = null

      try {
        const url = new URL(`${appConfig.strapiApiBaseUrl}/paragraphs`)
        url.searchParams.set('locale', this.locale)
        url.searchParams.append('filters[article][id][$eq]', this.selectedArticle.id)
        url.searchParams.append('sort', 'no:asc')

        const response = await fetch(url, {
          method: 'GET',
        })
        if (!response.ok) {
          throw new Error(`${response.url} - ${response.status}`)
        }

        this.paragraphs = await response.json()
      } catch (e) {
        this.error = this.$t('info_center.http_error')

        throw e
      } finally {
        this.loading = false
      }
    },
    async loadByReference(articleNo, paragraphNo) {
      this.loading = true
      this.error = null

      try {
        const url = new URL(`${appConfig.strapiApiBaseUrl}/articles`)
        url.searchParams.set('locale', this.locale)
        url.searchParams.append('filters[no][$eq]', articleNo)
        url.searchParams.append('populate[chapter][populate]', 'law_book')
        url.searchParams.append('populate[subchapter][populate][chapter][populate]', 'law_book')

        const response = await fetch(url, {
          method: 'GET',
        })
        if (!response.ok) {
          throw new Error(`${response.url} - ${response.status}`)
        }

        const result = await response.json()
        if (result.data.length === 0) {
          throw new Error('article not found')
        }

        const articleData = result.data[0]
        const lawBookData = this.getLawBookByArticle(articleData)
        await this.selectByReference(lawBookData, articleData, paragraphNo)
      } catch (e) {
        this.error = this.$t('info_center.http_error')

        throw e
      } finally {
        this.loading = false
      }
    },
    async selectByReference(lawBookData, articleData, paragraphNo) {
      this.scrollToParagraphNo = paragraphNo

      await this.setSelectedLawBook(lawBookData)
      await this.setSelectedArticle(articleData)
    },
  },
}
</script>

<template>
  <div
    class="d-flex flex-column"
    style="max-height: 100%"
  >

    <div class="d-flex align-center">
      <planitTextField
        prepend-inner-icon="fal fa-search"
        :append-icon="searchTerm ? 'fal fa-times-circle' : ''"
        :placeholder="$t('info_center.search_term')"
        v-model="searchTerm"
        @click:append="clearSearchTerm()"
        hide-details
        variant="outlined"
        class="mr-4 flex-grow-1"
      ></planitTextField>

      <PlanitButton
        variant="text"
        class="icon-btn pa-2 ml-auto"
        @click="setShowInfoCenter(false)"
      >
        <PlanitIcon
          size="small"
          color="grey-darken-1"
          icon="fas fa-chevron-double-right"
        />
      </PlanitButton>
    </div>

    <v-alert
      v-if="error"
      class="mt-4"
      type="error"
    >
      {{ error }}
    </v-alert>

    <v-progress-linear
      v-if="loading"
      class="mt-4"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <div
      v-if="isSearch"
      class="mt-4 mb-2"
    >
      <h3
        v-if="selectedArticle"
        class="text-primary cursor-pointer"
        @click="handleSelectLawBook(null, true)"
      >
        {{ $t('info_center.to_search_results') }}
      </h3>

      <prima-info-center-search
        v-else
        :locale="locale"
        :search-term="searchTerm"
        v-on:article-click="handleSelectSearchArticle"
        v-on:chapter-article-click="handleSelectSearchChapterArticle"
      />
    </div>
    <div v-else>
      <div class="d-flex ga-2 mt-4 mb-2">
        <h3
          :class="{ 'text-primary cursor-pointer': selectedLawBook }"
          @click="handleSelectLawBook(null, false)"
        >
          {{ $t('info_center.all_laws') }}
        </h3>

        <h3 v-if="selectedLawBook">
          <PlanitIcon icon="fas fa-chevron-right" size="small" />
        </h3>

        <h3
          v-if="selectedLawBook"
          :class="{ 'text-primary cursor-pointer': selectedArticle }"
          @click="handleSelectArticle(null)"
        >
          {{ selectedLawBook.attributes.heading }}
        </h3>

        <h3 v-if="selectedArticle">
          <PlanitIcon icon="fas fa-chevron-right" size="small" />
        </h3>

        <h3 v-if="selectedArticle">
          <span>{{ $t('info_center.article') }}</span>
          <span>&nbsp;</span>
          <span>{{ selectedArticle.attributes.no }}</span>
        </h3>
      </div>

      <div
        v-if="!selectedLawBook && lawBooks"
        class="d-flex flex-column ga-2"
      >
        <div
          v-for="lawBook in lawBooks.data"
          :key="lawBook.id"
          class="bg-white card-box-shadow border-radius pa-3 d-flex justify-space-between cursor-pointer"
          @click="handleSelectLawBook(lawBook, false)"
        >
          <div class="text-primary font-weight-medium">{{ lawBook.attributes.heading }}</div>

          <PlanitIcon
            size="small"
            color="primary"
            icon="fas fa-chevron-right"
          />
        </div>
      </div>

      <div v-if="selectedLawBook && !selectedArticle && chapters">
        <v-expansion-panels
          class="ga-2"
          variant="accordion"
          flat
          tile
          multiple
        >
          <prima-info-center-chapter-panel
            v-for="chapter in chapters.data"
            :key="chapter.id"
            :chapter="chapter"
            v-on:article-click="handleSelectArticle"
          />
        </v-expansion-panels>
      </div>
    </div>

    <div v-if="selectedArticle && paragraphs">
      <h2 class="mb-2">{{ selectedArticle.attributes.name }}</h2>
    </div>

    <div
      v-if="selectedArticle && paragraphs"
      class="overflow-auto d-flex flex-column ga-4"
    >
      <div
        v-for="paragraph, index in paragraphs.data"
        :key="'paragraph-' + index"
        class="d-flex ga-2 mb-4"
      >
        <div v-if="paragraphs.data.length > 1" v-scroll-to="paragraph.attributes.no === scrollToParagraphNo" class="text-primary">
          {{ $t('info_center.paragraph_no', { paragraphNo: paragraph.attributes.no }) }}
        </div>
        <div>
          <div
            v-for="(sentence, i) in splitParagraph(paragraph.attributes.text)"
            :key="'sentence-' + i"
            class="text-pre-wrap"
          >
            <template v-if="splitParagraph(paragraph.attributes.text).length > 1">
              <sub class="align-super select-none text-primary">{{ index + 1 }}</sub>
            </template>
            <span>{{ sentence }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
